import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, takeUntil } from 'rxjs';
import { ANIMATION } from 'src/app/helpers/animation';
import { pAdmin } from 'src/app/helpers/permission';
import { DESTROYER$, Unsubscribe } from 'src/app/helpers/unsubscribe';
import { CMS } from 'src/app/models/cms';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { ChildItem, MenuItem } from 'src/app/models/menu-item';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CmsService } from 'src/app/services/cms.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { UserService } from 'src/app/services/user.service';
import { MENU } from './menu';
import { ChangePasswordComponent } from 'src/app/shares/confirm-dialog/components/change-password/change-password.component';
import { SnackbarComponent } from 'src/app/shares/snackbar/components/snackbar/snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [ANIMATION.VISIBLE_CHANGE, ANIMATION.FADE_TOP, ANIMATION.FADE_BOTTOM, ANIMATION.NAVBAR]
})
export class ContainerComponent extends Unsubscribe implements OnInit {
  private readonly destroyer$ = DESTROYER$();

  pAdmin = pAdmin;
  menu!: MenuItem[];
  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;
  isFirefox: boolean;
  sidebarMode: any;
  mobileQuery!: boolean;
  smallScreen!: boolean;
  authUser!: object;
  isAuth!: boolean;
  isFullWidth: boolean = false;
  enableBgColor: boolean = false;

  account: any;
  authSubscribe: any;

  menuAdmin!: MenuItem[];

  langs: { [key: string]: string } = {
    'km': 'ខ្មែរ',
    'en': 'English'
  };
  content: CMS;

  browserLang: string;
  activeRoute: string;

  scrollHeight: number;

  isScrollToBottom: boolean = false;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  @HostListener('window:scroll', ['$event']) onScroll(event: Event) {
    this.scrollHeight = (event.srcElement as HTMLElement)?.scrollTop;

    if (this.scrollHeight > 0) this.enableBgColor = true;
    else this.enableBgColor = false;

    if (this.scrollHeight >= 600) this.isScrollToBottom = true;
    else this.isScrollToBottom = false;
  }

  constructor(
    public route: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    private localStorageService: LocalStorageService,
    private roleCheckerService: RoleCheckerService,
    private platform: Platform,
    private translate: TranslateService,
    private scrollService: ScrollService,
    private cmsService: CmsService,
    private profileService: ProfileService,
    private userDataService: UserDataService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {
    super();
    this.isFirefox = platform.FIREFOX;

    if (route.snapshot.queryParamMap.get('route') === 'skill') this.router.navigateByUrl(this.router.url);
    if (route.snapshot.queryParamMap.get('route') === 'scholarship-info') this.router.navigateByUrl(this.router.url);
    else router.navigate([], { queryParams: null });

    this.browserLang = localStorageService.get(LocalStorageEnum.language) || 'km';

    this.menuAdmin = MENU;
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-icon-set.svg')
    );
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-nav-icon-set.svg')
    );
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/action-icons.svg')
    );

    this.initSidenav();
    this.onGetCMS();

    this.route.queryParams.subscribe(params => {
      this.activeRoute = params.route ?? 'home';
    });

    this.authSubscribe = this.authService.authChange$.subscribe(isAuth => {
      this.isAuth = isAuth;
      if (this.isAuth) {
        this.userService
          .getAppliedScholarshipDetail()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.account = data;
            localStorage.setItem('account', JSON.stringify(data));
            this.profileService.staffId = data._id;
            this.userDataService.changeUserData(data);
            this.initSidenav();
          });
      }
    });
  }

  ngOnInit(): void {
    this.onSmallScreen();
    this.breakpointObserver.observe([Breakpoints.Large]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.mobileQuery = false;
      }
    });
  }

  onRouteChange(route: string): void {
    if (route === 'skill') {
      this.router.navigate(['home/search-school'], { queryParams: { route: route } });
    } else if (route === 'scholarship-info') {
      this.router.navigate(['home/scholarship-info'], { queryParams: { route: route } });
    } else {
      this.router.navigate([''], { queryParams: null });

      setTimeout(() => {
        this.router.navigate([''], { queryParams: { route: route } });
      }, 0);
    }
  }

  scrollToTop(): void {
    this.scrollService.scrollToElementById('home');
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.browserLang = language;
    this.localStorageService.set(LocalStorageEnum.language, language);
  }

  initSidenav() {
    this.menu = [];
    for (let i = 0; i < this.menuAdmin.length; i++) {
      if (this.menuAdmin[i].child.length > 0) {
        let childs: ChildItem[] = [];
        for (let j = 0; j < this.menuAdmin[i]?.child.length; j++) {
          if (this.menuAdmin[i]?.child[j].permissions.length > 0) {
            if (this.checkPermission(this.menuAdmin[i]?.child[j].permissions)) {
              childs.push(this.menuAdmin[i].child[j]);
            }
          } else {
            childs.push(this.menuAdmin[i].child[j]);
          }
        }

        if (childs.length > 0) {
          let m: MenuItem = JSON.parse(JSON.stringify(this.menuAdmin[i]));
          m.child = JSON.parse(JSON.stringify(childs));
          this.menu.push(m);
        }
      } else {
        // check permission menu without children
        if (this.menuAdmin[i].permissions.length > 0) {
          if (this.checkPermission(this.menuAdmin[i].permissions)) {
            this.menu.push(this.menuAdmin[i]);
          }
        } else {
          this.menu.push(this.menuAdmin[i]);
        }
      }
    }
  }

  public checkPermission(arr: string[]): boolean {
    let perms: string[] = this.roleCheckerService.GetPermissions();

    if (perms == undefined || perms.length <= 0) {
      return false;
    }
    for (let i = 0; i < arr.length; i++) {
      if (perms.filter(e => e == arr[i]).length > 0) {
        return true;
      }
    }
    return false;
  }

  isChildActive(childs: MenuItem[] | ChildItem[]): boolean {
    for (let i = 0; i < childs.length; i++) {
      if (this.router.isActive(this.router.createUrlTree(childs[i].route), false)) {
        return true;
      }
    }
    return false;
  }

  onResize(): void {
    if (window.innerWidth <= 959) {
      this.mobileQuery = true;
      this.isExpanded = false;
    }
    if (window.innerWidth > 959 && window.innerWidth <= 1280) {
      this.isExpanded = false;
      this.mobileQuery = false;
    }
  }
  onSmallScreen(): void {
    if (window.innerWidth <= 959) {
      this.mobileQuery = true;
      this.isExpanded = false;
    }
    if (window.innerWidth > 959 && window.innerWidth <= 1280) {
      this.isExpanded = false;
      this.mobileQuery = false;
    }
  }

  toggleSideNav(): void {
    if (this.mobileQuery) {
      this.sidenav.toggle();
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  logout(): void {
    this.isAuth = false;
    // this.authSubscribe.unsubscribe();
    this.authService.logout();
    this.router.navigateByUrl('/login');
    this.account = null;
  }

  onGetCMS(): void {
    this.cmsService
      .getCms()
      .pipe(
        map(map => {
          return !!map && Object.keys(map)?.length < 2 && Object.keys(map)[0] === 'data' ? map['data'] : map;
        }),
        takeUntil(this.destroyer$)
      )
      .subscribe({
        next: (res: CMS) => {
          this.content = res;
          this.content.name = this.domSanitizer.bypassSecurityTrustHtml(res?.name as string);
          this.content.description = this.domSanitizer.bypassSecurityTrustHtml(res?.description as string);
        }
      });
  }

  openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '650px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = {
          old_password: result.old_password,
          new_password: result.new_password
        };

        this.profileService
          .changePassword(data)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: () => {
              this.snackbarService.onShowSnackbar({ message: 'change-password', component: SnackbarComponent });
            }
          });
      }
    });
  }
}
