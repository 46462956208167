import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        children: [
          {
            path: '',
            loadChildren: async () => (await import('./routes/home/home.module')).HomeModule
          },
          {
            path: 'search-school',
            loadChildren: async () => (await import('./routes/search-school/search-school.module')).SearchSchoolModule
          },
          {
            path: 'scholarship-info',
            loadChildren: async () =>
              (await import('./routes/info-scholarship/info-scholarship.module')).InfoScholarshipModule
          }
        ]
      },
      {
        path: 'request-scholarship',
        loadChildren: async () =>
          (await import('./routes/request-scholarship/request-scholarship.module')).RequestScholarshipModule
      },
      {
        path: 'login',
        loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'user-info',
        loadChildren: async () => (await import('./routes/user/user.module')).UserModule
      }
    ]
  },
  {
    path: 'policy',
    loadChildren: () => import('./routes/policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], //{ relativeLinkResolution: 'legacy' }
  exports: [RouterModule]
})
export class AppRoutingModule {}
