<div class="app-menu" (window:resize)="onResize()">
  <mat-sidenav-container class="app-container" autosize>
    <mat-sidenav
      *ngIf="mobileQuery"
      #sidenav
      class="example-sidenav"
      [class.large-width]="mobileQuery"
      [mode]="mobileQuery ? 'over' : 'side'"
      [opened]="!mobileQuery"
      [disableClose]="!mobileQuery"
    >
      <div class="main-logo" (click)="onRouteChange('home')">
        <div class="logo-container">
          <img class="img" [src]="'assets/imgs/new-logo.png'" alt="logo-image" />
        </div>
      </div>
      <mat-nav-list class="menu-list mt-0" id="list-nav">
        <div *ngFor="let m of menu">
          <mat-list-item
            *ngIf="m.child.length <= 0 "
            [ngClass]="{'active': activeRoute === m.ref && router.url?.includes('home')}"
            (click)="onRouteChange(m.ref)"
          >
            <span class="menu-item-title">{{ m.title | translate}}</span>
          </mat-list-item>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [class.explicit-sm-ml]="!mobileQuery" [class.explicit-lg-ml]="!mobileQuery">
      <div class="example-sidenav-content app-root-content" (scroll)="onScroll($event)">
        <mat-toolbar class="dashboard-toolbar" [class.blurred]="!isFirefox" @animate>
          <span class="bg-color" [@fadeTop]="enableBgColor"></span>

          <header class="dashboard__header standard-container animated">
            <section class="flex items-center gap-x-8">
              <button *ngIf="mobileQuery" class="icon" mat-icon-button (click)="toggleSideNav()">
                <mat-icon svgIcon="menu-hamburger"></mat-icon>
              </button>
              <!-- <img
                [src]="mobileQuery ? 'assets/imgs/logo-sm.svg' : 'assets/imgs/ministry.svg'"
                alt="logo-image"
                (click)="onRouteChange('home')"
              /> -->

              <img class="img" [src]="'assets/imgs/mlvt.png'" alt="logo-image" (click)="onRouteChange('home')" />
              <img class="pl-8 img" [src]="'assets/imgs/social.png'" alt="logo-image" (click)="onRouteChange('home')" />
            </section>

            <mat-nav-list class="menu-list mt-0" *ngIf="!mobileQuery">
              <div *ngFor="let m of menu">
                <mat-list-item
                  *ngIf="m.child.length <= 0"
                  [ngClass]="{'active': activeRoute === m.ref && router.url?.includes('home')}"
                  (click)="onRouteChange(m.ref)"
                >
                  <span class="menu-item-title">{{ m.title | translate }}</span>
                </mat-list-item>
              </div>
            </mat-nav-list>

            <div class="header-action">
              <button mat-button *ngIf="!!account; else noAccount" [matMenuTriggerFor]="menu1">
                <div class="flex items-center gap-x-8">
                  <img
                    *ngIf="account?.profile_image; else noImage"
                    class="profile-picture"
                    [src]="(account?.profile_image | staticFile: 'download') + '?image_size=s'"
                    alt="user placeholder"
                  />
                  <ng-template #noImage>
                    <img class="profile-picture" src="/assets/icons/user-placeholder.svg" alt="user placeholder" />
                  </ng-template>
                  <span class="mobile-responsive">{{account | fullName}}</span>
                </div>
              </button>

              <ng-template #noAccount>
                <button mat-button routerLink="login">
                  <div class="flex items-center gap-x-8">
                    <img class="profile-picture" src="/assets/icons/user-placeholder.svg" alt="user placeholder" />
                    <span class="mobile-responsive">ចូលប្រើប្រាស់</span>
                  </div>
                </button>
              </ng-template>

              <mat-menu #menu1="matMenu">
                <button mat-menu-item routerLink="/user-info">
                  <mat-icon svgIcon="mat-view"></mat-icon>
                  <span>គ្រប់គ្រងសំណើ</span>
                </button>
                <button mat-menu-item (click)="openChangePasswordDialog()">
                  <mat-icon color="primary">lock</mat-icon>
                  <span>ផ្លាស់ប្តូរពាក្យសម្ងាត់</span>
                </button>
                <button mat-menu-item (click)="logout()">
                  <mat-icon color="warn">logout</mat-icon>
                  <span>ចាកចេញ</span>
                </button>
              </mat-menu>
            </div>
          </header>
        </mat-toolbar>

        <section class="dash-row" id="home">
          <router-outlet></router-outlet>
        </section>

        <section class="scroll-to-top" *ngIf="isScrollToBottom" @fadeBottom>
          <button mat-fab color="info" (click)="scrollToTop()">
            <mat-icon>arrow_upward_alt</mat-icon>
          </button>
        </section>

        <ng-container *ngIf="!router.url?.includes('login')">
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #footer>
  <div class="home">
    <div class="content-wrapper">
      <section class="content article__footer">
        <div class="standard-container">
          <footer>
            <div class="flex justify-content-between flex-wrap gap-16">
              <section class="footer__contact">
                <!-- <p class="head-title">Contact</p> -->

                <div class="items-center flex-wrap gap-16 mt-16 pt-0">
                  <div class="flex gap-x-16">
                    <mat-icon svgIcon="location"></mat-icon>
                    <p>{{"footer.address" | translate}}</p>
                  </div>

                  <div class="flex items-center gap-x-16">
                    <mat-icon svgIcon="telephone"></mat-icon>
                    <p>ព័ត៌មានចុះឈ្មោះចូលរៀន៖ 1297</p>
                  </div>
                  <div class="flex items-center gap-x-16">
                    <mat-icon svgIcon="telephone"></mat-icon>
                    <p>ព័ត៌មានការបើកផ្តល់ប្រាក់ឧបត្ថម្ភ៖ 015219888</p>
                  </div>
                </div>
              </section>

              <section class="footer__media flex items-center gap-x-16">
                <a href="https://www.facebook.com/mlvt.gov.kh?mibextid=ZbWKwL" target="_blank">
                  <button mat-mini-fab color="base">
                    <mat-icon svgIcon="facebook"></mat-icon>
                  </button>
                </a>
                <a href="https://t.me/molvtkhmer" target="_blank">
                  <button mat-mini-fab color="base">
                    <mat-icon svgIcon="telegram"></mat-icon>
                  </button>
                </a>
              </section>
            </div>

            <div class="mt-16 mb-16">
              <mat-divider></mat-divider>
            </div>

            <p class="footer__title-bottom">{{'footer.bottom_title' | translate}}</p>
          </footer>
        </div>
      </section>
    </div>
  </div>
</ng-template>
