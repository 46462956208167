import { AUTO_STYLE, animate, group, query, stagger, state, style, transition, trigger } from '@angular/animations';

const DELAY_DURATION: number = 500;

const NAVBAR = trigger('animate', [
  transition(':enter', [
    group([
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger('500ms', [
          animate(
            `500ms ${DELAY_DURATION}ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ])
      )
    ])
  ])
]);

const TOP_CONTENT = trigger('animate', [
  transition(':enter', [
    group([
      query('.animated-background', [
        style({ opacity: 0, transform: 'translate(-100%,-100%)', 'border-bottom-right-radius': '100%' }),
        animate(`${DELAY_DURATION}ms ease-in`)
      ]),
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger('300ms', [
          animate(
            `1100ms ${DELAY_DURATION}ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ])
      ),
      query('.animated-image', style({ opacity: 0, transform: 'translateY(-25px)' }), { optional: true }),
      query(
        '.animated-image',
        stagger('0ms', [
          animate(
            `1900ms ${DELAY_DURATION + 350}ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ]),
        { optional: true }
      )
    ])
  ])
]);

const LOG_IN = trigger('animate_login', [
  transition(':enter', [
    group([
      query('.app', [
        style({
          opacity: 0,
          transform: 'translate(-100%,-100%)',
          'border-bottom-right-radius': '100%'
        }),
        animate(`${DELAY_DURATION}ms ease-in`)
      ]),
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger('300ms', [
          animate(
            `1100ms ${DELAY_DURATION}ms cubic-bezier(0.2, 0, 0.1, 1)`,
            style({ opacity: 1, transform: 'translateY(0)' })
          )
        ])
      )
    ])
  ])
]);

const VISIBLE_CHANGE = trigger('isVisibleChanged', [
  state('true', style({ opacity: 1 })),
  state('false', style({ opacity: 0 })),
  transition('1 => 0', animate('10ms')),
  transition('0 => 1', animate('900ms'))
]);

const FADE_TOP = trigger('fadeTop', [
  state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, transform: 'translateY(0)' })),
  state('false', style({ height: '0', visibility: 'hidden', opacity: 0, transform: 'translateY(-100%)' })),
  transition('false => true', animate(300 + 'ms ease-out')),
  transition('true => false', animate(300 + 'ms ease'))
]);

const FADE_BOTTOM = trigger('fadeBottom', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate('300ms', style({ opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translate(0, 0)' }),
    animate('300ms', style({ opacity: 0, transform: 'translateY(100%)' }))
  ])
]);

const COLLAPSE = trigger('collapse', [
  state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })),
  state('true', style({ height: '0', visibility: 'hidden', opacity: 0 })),
  transition('false => true', animate(300 + 'ms ease-out')),
  transition('true => false', animate(300 + 'ms ease'))
]);

const CARD = trigger('animate', [
  transition(':enter', [
    group([
      query('.animated', style({ opacity: 0, transform: 'translateY(-20px)' })),
      query(
        '.animated',
        stagger('200ms', [
          animate(`200ms 200ms cubic-bezier(0.2, 0, 0.1, 1)`, style({ opacity: 1, transform: 'translateY(0)' }))
        ])
      )
    ])
  ])
]);

const COLLAPSE_INFO = trigger('collapse_info', [
  state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, 'margin-bottom': '16px' })),
  state('true', style({ height: '0', visibility: 'hidden', opacity: 0, 'margin-bottom': 0 })),
  transition('false => true', animate(300 + 'ms ease-out')),
  transition('true => false', animate(300 + 'ms ease'))
]);

export const ANIMATION = {
  NAVBAR,
  TOP_CONTENT,
  FADE_TOP,
  FADE_BOTTOM,
  VISIBLE_CHANGE,
  COLLAPSE,
  CARD,
  COLLAPSE_INFO,
  LOG_IN
};
