import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { CMS } from '../models/cms';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService extends BaseCrudService<CMS> {
  constructor(injector: Injector) {
    super(injector);
    this.path = '/user/landing_page_cms';
  }

  getCms(): Observable<CMS> {
    return this.requestService.getJSON<CMS>(this.path);
  }

  updateCms(data: CMS): Observable<CMS> {
    return this.requestService.patchJSON<CMS>(this.path, { data });
  }
}
