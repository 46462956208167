import { MenuItem } from 'src/app/models/menu-item';

export const MENU: MenuItem[] = [
  {
    title: 'menu.dashboard',
    route: '',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: [],
    ref: 'home'
  },
  {
    title: 'ជំនាញ',
    route: '',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: [],
    ref: 'skill'
  },
  {
    title: 'ព័ត៌មានអាហារូបករណ៍',
    route: '',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: [],
    ref: 'scholarship-info'
  }
];
