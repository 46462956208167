import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckingUsername, User } from '../models/user';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseCrudService<User> {
  constructor(injector: Injector) {
    super(injector);
    this.path = '/user/account';
  }

  checkExistedUser(data: { username: string }): Observable<CheckingUsername> {
    return this.requestService.getJSON(`${this.path}/check_existed_user`, { data });
  }

  getAppliedScholarshipDetail(): Observable<User> {
    return this.requestService.getJSON(`user/apply_study/applied_detail`, { });
  }
}
